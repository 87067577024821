import React from "react"
import Layout, { SimpleLayout } from "../components/layout"
import Hero from "../components/hero"
import Card from "../components/card"
import imageBLS from "../images/cards/card-bls.png"
import imageACLS from "../images/cards/card-acls.png"
import imagePALS from "../images/cards/card-pals.png"
import imageOBH from "../images/cards/card-obh.png"
import imageAUS from "../images/cards/card-aus.png"
import imageCustom from "../images/cards/card-custom.svg"

import { FooterWrapper } from "../components/footer"

import FAQ from "../components/faq"
import Quote from "../components/quote"
import DialogBox from "../components/dialogbox"
import CircleText from "../components/circletext"

//Benefits
import ThreeCircles from "../components/threecircles"
import imageFrequent from "../images/healthcare/frequent.svg"
import imageImprove from "../images/healthcare/improve.svg"
import imageReal from "../images/healthcare/real.svg"
//Additional
import imageAPI from "../images/healthcare/api.svg"
import imageVRDevice from "../images/healthcare/vrdevice.svg"
import imageSupport from "../images/healthcare/support.svg"
import imageTracking from "../images/healthcare/tracking.svg"
import imageCustomise from "../images/healthcare/customise.svg"
import imageAccount from "../images/healthcare/account.svg"

import imageBackground from "../images/healthcare/healthcare-background.jpg"

export default () => {
  let cardHeight = "520px"
  let additionalFeatures = [
    { image: imageAPI, text: "API integration <br> with LMS" },
    { image: imageVRDevice, text: "Compatible with various VR devices" },
    { image: imageSupport, text: "Software support <br> & updates" },
    { image: imageTracking, text: "Performance tracking with CSV export" },
    { image: imageCustomise, text: "Customisation <br> options" },
    { image: imageAccount, text: "Account management via back end" },
  ]
  return (
    <FooterWrapper>
      <Layout image={imageBackground}>
        <Hero
          center
          hero="VR for Healthcare"
          herosub="Browse through our product offering in the area of Life Support Training and beyond"
        />
        <div style={{ margin: "120px 0" }}>
          <div className="card-feed">
            <Card
              title="Basic Life Support"
              buttonTitle="FIND OUT MORE"
              cardImage={imageBLS}
              download={true}
              height={cardHeight}
            >
              <h2>BLS training</h2>
              <h3>For Healthcare staff</h3>
              <p>
                This course covers the basics of Life Support training for
                Healthcare professionals, in both hospital and out-of-hospital
                setting.
              </p>
            </Card>

            <Card
              title="Advanced Life Support"
              buttonTitle="FIND OUT MORE"
              cardImage={imageACLS}
              download={true}
              height={cardHeight}
            >
              <h2>ACLS & ALS training</h2>
              <h3>For Code, Emergency and Paramedic teams</h3>
              <p>
                Expanding on the BLS course, this course includes a set of
                life-saving protocols and skills to further support the
                circulation and provide an open airway and adequate ventilation.
              </p>
            </Card>

            <Card
              title="Paediatric Life Support"
              buttonTitle="FIND OUT MORE"
              cardImage={imagePALS}
              enquire={true}
              height={cardHeight}
            >
              <h2>PALS & PLS training</h2>
              <h3>For Healthcare staff</h3>
              <p>
                This course focuses on Life Support training for the treatment
                to pediatric patients. The course is specifically made for the
                training of Healthcare professionals.
              </p>
            </Card>

            <Card
              title="Obstetric Hemorrhage"
              buttonTitle="FIND OUT MORE"
              cardImage={imageOBH}
              enquire={true}
              height={cardHeight}
            >
              <h2>OB Hemorrhage training</h2>
              <h3>For Obstetrics specialists</h3>
              <p>
                Created for Obstetric professionals, the training programme
                helps specialists prepare for dealing with Postpartum Hemorrhage
                cases.
              </p>
            </Card>

            <Card
              title="Auscultation Trainer"
              buttonTitle="FIND OUT MORE"
              cardImage={imageAUS}
              enquire={true}
              height={cardHeight}
            >
              <h2>Auscultation training</h2>
              <h3>For Healthcare Staff</h3>
              <p>
                This Virtual Reality training experience offers an interactive
                way to learn key auscultation skills related to the circulatory
                system.
              </p>
            </Card>

            <Card
              title="Custom Training"
              buttonTitle="FIND OUT MORE"
              cardImage={imageCustom}
              enquire={true}
              height={cardHeight}
            >
              <h2>Bespoke courses</h2>
              <h3>For Healthcare staff</h3>
              <p>
                We can provide various Healthcare training modules either as
                supplement to the existing training modules, or as a standalone
                product.
              </p>
            </Card>
          </div>
        </div>
      </Layout>

      <ThreeCircles
        title="Why VR training for Healthcare?"
        circle1={{
          image: imageFrequent,
          text: "Frequent training for staff",
          subtext:
            "VR allows Healthcare staff to train a lot more often than mandatory training requirements suggest",
        }}
        circle2={{
          image: imageImprove,
          text: "Improved learning outcomes",
          subtext:
            "Research suggests that immersive technology improves knowledge retention",
        }}
        circle3={{
          image: imageReal,
          text: "Realistic scenario training",
          subtext:
            "Experiencing a real-life scenario by VR simulation also improves learning outcomes",
        }}
      >
        <p>
          Key benefits of immersive training for the Healthcare sector include:
        </p>
      </ThreeCircles>

      <CircleText data={additionalFeatures}>
        <h1>Additional features</h1>
        <p>
          All products have a user-centered approach with the aim of delivering
          engaging scenario training. Some training scenarios also incorporate
          ‘hands-on’ approaches by using external objects to deliver tactile
          feedback. Additional features of all courses are:
        </p>
      </CircleText>

      <Quote
        quote="Dual Good Health have been very responsive to our needs and the communication has
been great. They have been excellent to work with this far and we are looking forward to
the launch in the next week or so.
We are optimistic this could result in a great
long partnership and would recommend Dual Good Health to colleagues in other
hospitals as the experience has been great. Continue the good work."
        quotee="Senior Clinical Manager Clinical Services, US hospital"
      />

      <DialogBox />

      <SimpleLayout style={{ background: "white", paddingBottom: "30px" }}>
        <h1 style={{ paddingTop: "60px", paddingBottom: "30px" }}>
          Frequently asked questions
        </h1>
        <FAQ
          question="Which VR devices is the software compatible with?"
          answer="All VR devices widely available on the market that are suitable for organisational use. This includes HTC Vive, Oculus Go, Oculus Rift and Oculus Quest (device to be released Q2 of 2019)."
        />
        <FAQ
          question="How many VR headsets do I need?"
          answer="It depends on the type of device and training setting. If your organisation has a VR room already and/or is lookig to train each employee individually, even just one HTC Vive device may be enough. If group training is your preferred option, we recommend at least 3 headsets for a group of 12 people, which can also be Oculus devices. "
        />
        <FAQ
          question="Do you provide VR hardware as part of the package?"
          answer="Normally we don’t. Depending on your location, however, we may be able to. Get in touch and we will do our best to help out. Note that, to all clients, we always provide advice on hardware purchasing options."
        />

        <FAQ
          question="Are software support and/or updates included?"
          answer="Absolutely! With all our packages you get constant software support by a friendly and responsive team. We also add new useful features to the software quite frequently, which are available at no additional cost. "
        />
        <FAQ
          question="Does the course curriculum follow official guidelines?"
          answer="Yes, it does. The curriculum is strictly following the corresponding guidelines by official bodies such as Resuscitation  Councils in Europe and American Heart Association in the US and internationally. "
        />
        <hr style={{ marginTop: "20px", marginBottom: "80px" }} />
        <h2 style={{ marginBottom: "30px", textAlign: "center" }}>
          Have more questions?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <a href="mailto:hello@dualgoodhealth.com" className="button">
            CONTACT US
          </a>
        </div>
      </SimpleLayout>
    </FooterWrapper>
  )
}
